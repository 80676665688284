import React, { useState, useEffect } from 'react';
import DisplayBooksGrid from './DisplayBooksGrid';
import { useParams } from 'react-router-dom';
import SidebarMenuTwo from '../Header/SideBarMenuTwo';
import Header from './Header';
import SearchBar from './SearchBar';

const GenreBasedBook = ({
  books,
  ageCategories,
  selectedAges,
  setSelectedAges,
  genres,
  selectedGenres,
  setSelectedGenres,
  selectedConditions,
  setSelectedConditions,
  searchTerm,
  setSearchTerm,
  loading,
}) => {
  const { genre } = useParams(); 
  const [filteredBooks, setFilteredBooks] = useState([]);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  useEffect(() => {
    let updatedBooks = books;

    // Automatically select the genre from the URL in the filter
    if (genre && !selectedGenres.includes(genre)) {
      setSelectedGenres([genre]);
    }

    if (selectedAges.length > 0) {
      updatedBooks = updatedBooks.filter(book => selectedAges.includes(book.ageGroup));
    }
    if (selectedGenres.length > 0) {
      updatedBooks = updatedBooks.filter(book => selectedGenres.includes(book.genre));
    }
    if (selectedConditions.length > 0) {
      updatedBooks = updatedBooks.filter(book => selectedConditions.includes(book.condition));
    }
    if (searchTerm.length > 2) {
      updatedBooks = updatedBooks.filter(book =>
        (book.title && book.title.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (book.author && book.author.toLowerCase().includes(searchTerm.toLowerCase()))
      );
    }

    setFilteredBooks(updatedBooks);
  }, [genre, books, selectedAges, selectedGenres, selectedConditions, searchTerm]);

  return (
    <div>
      <Header />
      <SearchBar searchTerm={searchTerm} onSearch={setSearchTerm} /> 
      <div className='menu-bar'>
        <SidebarMenuTwo
          genres={genres}
          ageCategories={ageCategories}
          selectedAges={selectedAges}
          onAgeSelect={setSelectedAges}
          selectedGenres={selectedGenres}
          onGenreSelect={setSelectedGenres}
          selectedConditions={selectedConditions}
          onConditionSelect={setSelectedConditions}
          toggleSidebar={toggleSidebar}
        />
      </div>
      <div style={{ marginTop: '155px', padding: '20px', width: '100%', height: '350px' }}>
        <div style={{ marginTop: '20px', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', overflowY: 'auto' }}>
          {loading ? (
            <p>Loading books...</p>
          ) : (
            <DisplayBooksGrid books={filteredBooks} />
          )}
        </div>
      </div>
    </div>
  );
};

export default GenreBasedBook;
