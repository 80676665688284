
import axios from "axios";

// Create an Axios instance
const axiosInstance = axios.create();

// Axios loading management
export const setupAxiosInterceptors = (setAxiosLoading) => {
  // Request interceptor to start loading
  axiosInstance.interceptors.request.use(
    (config) => {
      setAxiosLoading(true);
      return config;
    },
    (error) => {
      setAxiosLoading(false);
      return Promise.reject(error);
    }
  );

  // Response interceptor to stop loading
  axiosInstance.interceptors.response.use(
    (response) => {
      setAxiosLoading(false);
      return response;
    },
    (error) => {
      setAxiosLoading(false);
      return Promise.reject(error);
    }
  );
};

export default axiosInstance;
