import React, { useState, useEffect ,useMemo} from "react";
import DisplayBooksGrid from "./DisplayBooksGrid";
import { useParams } from "react-router-dom";
import SidebarMenuTwo from "../Header/SideBarMenuTwo";
import Header from "./Header";
import SearchBar from "./SearchBar";
import Breadcrumb from "../wrapper/Breadcrumb";

const ViewMorePage = ({
  books,
  ageCategories,
  selectedAges,
  setSelectedAges,
  genres,
  selectedGenres,
  setSelectedGenres,
  selectedConditions,
  setSelectedConditions,
  searchTerm,
  setSearchTerm,
  loading,
  staffBooks,
  basedOnyourViews,
}) => {
  let items = [];
  const { book } = useParams(); // Fetching route parameter

  // Determine which set of books to display
  items = useMemo(() => {
    if (book === "book") {
      return books;
    } else if (book === "staff") {
      return staffBooks;
    } else {
      return basedOnyourViews;
    }
  }, [book, books, staffBooks, basedOnyourViews]);

  // Define state for filteredBooks
  const [filteredBooks, setFilteredBooks] = useState(items);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  useEffect(() => {
    let updatedBooks = items;
    if (selectedAges.length > 0) {
      updatedBooks = updatedBooks.filter((book) =>
        selectedAges.includes(book.ageGroup)
      );
    }
    if (selectedGenres.length > 0) {
      updatedBooks = updatedBooks.filter((book) =>
        selectedGenres.includes(book.genre)
      );
    }
    if (selectedConditions.length > 0) {
      updatedBooks = updatedBooks.filter((book) =>
        selectedConditions.includes(book.condition)
      );
    }
    if (searchTerm.length > 2) {
      updatedBooks = updatedBooks.filter(
        (book) =>
          (book.title &&
            book.title.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (book.author &&
            book.author.toLowerCase().includes(searchTerm.toLowerCase()))
      );
    }
    setFilteredBooks(updatedBooks);
  }, [selectedAges, selectedGenres, selectedConditions, searchTerm, items]);

  return (
    <div>
      <Header />
      <SearchBar searchTerm={searchTerm} onSearch={setSearchTerm} />
      <div className="menu-bar">
        <SidebarMenuTwo
          genres={genres}
          ageCategories={ageCategories}
          selectedAges={selectedAges}
          onAgeSelect={setSelectedAges}
          selectedGenres={selectedGenres}
          onGenreSelect={setSelectedGenres}
          selectedConditions={selectedConditions}
          onConditionSelect={setSelectedConditions}
          toggleSidebar={toggleSidebar}
        />
      </div>
      <div style={{ marginTop: "180px" }}>
        <Breadcrumb
          pages={[
            { label: "Home", path: "/" },
            { label: "AllBooks", path: "/view-more/book" },
          ]}
        />
      </div>
      <div
        style={{
          marginTop: "20px",
          padding: "20px",
          width: "100%",
          height: "350px",
        }}
      >
        <div
          style={{
            marginTop: "20px",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            overflowY: "auto",
          }}
        >
          <DisplayBooksGrid books={filteredBooks} />
        </div>
      </div>
    </div>
  );
};

export default ViewMorePage;
