import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import '../css/Footer.css'; 

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-column" style={{ fontFamily: "Quicksand, sans-serif" }}>
          <img 
            src="jingus-logo1.png" 
            alt="Footer Logo" 
            className="footer-logo" 
          />
        </div>
        <div className="footer-column">
          <div className="footer-section">
            <div className="footer-heading">ABOUT US</div>
            <ul className="footer-list">
              <li><Link to="/about">About us</Link></li>
              <li><Link to="/contact">Contact</Link></li>
            </ul>
          </div>
        </div>
        <div className="footer-column">
          <div className="footer-section">
            <div className="footer-heading">USEFUL LINKS</div>
            <ul className="footer-list">
              <li><Link to="/returns">Returns</Link></li>
              <li><Link to="/support-policy">Support Policy</Link></li>
            </ul>
          </div>
        </div>
        <div className="footer-column">
          <div className="footer-section">
            <div className="footer-heading">FOLLOW US</div>
            <ul className="footer-list">
              <li><a href="//www.facebook.com" target="_blank" rel="noopener noreferrer">Facebook</a></li>
              <li><a href="//www.twitter.com" target="_blank" rel="noopener noreferrer">Twitter</a></li>
              <li><a href="//www.instagram.com" target="_blank" rel="noopener noreferrer">Instagram</a></li>
              <li><a href="//www.youtube.com" target="_blank" rel="noopener noreferrer">Youtube</a></li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
