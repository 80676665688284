import { useDispatch, useSelector } from "react-redux";
import {
  remove,
  increaseQuantity,
  decreaseQuantity,
} from "../Store/BorrowCart";
import {
  buyRemove,
  buyIncreaseQuantity,
  buyDecreaseQuantity,
} from "../Store/BuyCart";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import Footer from "../assets/Footer";
import { Fragment } from "react";
import Breadcrumb from "../wrapper/Breadcrumb";
import Header from "./Header";
import SearchBar from "./SearchBar";
import DisplayBooksGrid from "./DisplayBooksGrid";
import "../css/cart.css";

export const Cart = ({ searchTerm, onSearch, books }) => {
  const phoneNumber = "+917842631444";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const borrowBooks = useSelector((state) => state.BorrowCart.items) || [];
  const buyingBooks = useSelector((state) => state.BuyCart.items) || [];
  const borrowAmount = useSelector((state) => state.BorrowCart.Amount);
  const buyingAmount = useSelector((state) => state.BuyCart.Amount);
  const cautionDeposit = borrowBooks.reduce((total, book) => {
    return total + parseInt(book.sellingPrice || 0); // Ensure sellingPrice is defined
  }, 0);
  console.log(cautionDeposit);

  const sendWhatsAppMessage = () => {
    let message = `Checkout Details:\n\nBorrowed Books:\n`;

    borrowBooks.forEach((book, index) => {
      message += `${index + 1}. ${book.title} (ISBN: ${book.isbn}) - ${
        book.quantity
      } copies, ₹${book.lendingPricePerDay * book.quantity}/day\n`;
    });

    message += `\nBuying Books:\n`;
    buyingBooks.forEach((book, index) => {
      message += `${index + 1}. ${book.title} (ISBN: ${book.isbn}) - ${
        book.quantity
      } copies, ₹${book.sellingPrice * book.quantity}\n`;
    });

    message += `\nBorrow Price per Day: ₹${borrowAmount}`;
    message += `\nBuying Total: ₹${buyingAmount}`;
    message += `\nGrand Total: ₹${cautionDeposit + buyingAmount}`;

    const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappUrl, "_blank");
  };

  const handleClearCart = () => {
    localStorage.removeItem("borrowCartState");
    localStorage.removeItem("buyCartState");
    window.location.reload();
  };

  let sno = 0;
  const hasItems = borrowBooks.length > 0 || buyingBooks.length > 0;

  return (
    <Fragment>
      <Header />
      <SearchBar searchTerm={searchTerm} onSearch={onSearch} />
      <div style={{ marginTop: "150px" }}></div>
      <Breadcrumb
        pages={[
          { label: "Home", path: "/" },
          { label: "Cart", path: "/cart" },
        ]}
      />
      {/* Only display the Cart if no search term is being used */}
      <div className="cart-main-area pt-90 pb-100">
        <div className="container">
          {hasItems ? (
            <Fragment>
              <h3 className="cart-page-title">Your cart items</h3>
              <div className="row">
                <div className="col-12">
                  <div className="table-content table-responsive cart-table-content">
                    <table>
                      <thead>
                        <tr>
                          <th>S.no</th>
                          <th></th>
                          <th>Product Name</th>
                          <th>Borrow Price</th>
                          <th>CautionDeposit</th>
                          <th>Buy Price</th>
                          <th>Qty</th>
                          <th>Subtotal</th>
                          <th>Remove</th>
                        </tr>
                      </thead>
                      <tbody>
                        {borrowBooks.map((cartItem, key) => (
                          <tr key={key}>
                            <td className="product-name">{++sno}</td>
                            <td className="product-thumbnail">
                              <img
                                className="img-fluid"
                                src={cartItem.imageUrl}
                                alt=""
                              />
                            </td>
                            <td className="product-name">{cartItem.title}</td>
                            <td className="product-price-cart">
                              <span className="amount">
                                {cartItem.lendingPricePerDay}
                              </span>
                            </td>
                            <td className="product-price-cart">
                              <span className="amount">
                                {cartItem.sellingPrice}
                              </span>
                            </td>
                            <td className="product-price-cart">-</td>
                            <td className="product-quantity">
                              <div className="cart-plus-minus">
                                <button
                                  className="dec qtybutton"
                                  onClick={() =>
                                    dispatch(decreaseQuantity(cartItem))
                                  }
                                >
                                  -
                                </button>
                                <input
                                  className="cart-plus-minus-box"
                                  type="text"
                                  value={cartItem.quantity}
                                  readOnly
                                />
                                <button
                                  className="inc qtybutton"
                                  onClick={() =>
                                    dispatch(increaseQuantity(cartItem))
                                  }
                                >
                                  +
                                </button>
                              </div>
                            </td>
                            <td className="product-subtotal">
                              {cartItem.lendingPricePerDay * cartItem.quantity}
                            </td>
                            <td className="product-remove">
                              <button
                                onClick={() => dispatch(remove(cartItem))}
                              >
                                <FontAwesomeIcon icon={faXmark} />
                              </button>
                            </td>
                          </tr>
                        ))}

                        {buyingBooks.map((cartItem, key) => (
                          <tr key={key}>
                            <td className="product-name">{++sno}</td>
                            <td className="product-thumbnail">
                              <img
                                className="img-fluid"
                                src={cartItem.imageUrl}
                                alt=""
                              />
                            </td>
                            <td className="product-name">{cartItem.title}</td>
                            <td className="product-price-cart">-</td>
                            <td className="product-price-cart"> - </td>
                            <td className="product-price-cart">
                              <span className="amount">
                                {cartItem.sellingPrice}
                              </span>
                            </td>
                            <td className="product-quantity">
                              <div className="cart-plus-minus">
                                <button
                                  className="dec qtybutton"
                                  onClick={() =>
                                    dispatch(buyDecreaseQuantity(cartItem))
                                  }
                                >
                                  -
                                </button>
                                <input
                                  className="cart-plus-minus-box"
                                  type="text"
                                  value={cartItem.quantity}
                                  readOnly
                                />
                                <button
                                  className="inc qtybutton"
                                  onClick={() =>
                                    dispatch(buyIncreaseQuantity(cartItem))
                                  }
                                >
                                  +
                                </button>
                              </div>
                            </td>
                            <td className="product-subtotal">
                              {cartItem.sellingPrice * cartItem.quantity}
                            </td>
                            <td className="product-remove">
                              <button
                                onClick={() => dispatch(buyRemove(cartItem))}
                              >
                                <FontAwesomeIcon icon={faXmark} />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <div className="cart-shiping-update-wrapper">
                    <div className="cart-shiping-update">
                      <Link to="/">Continue Shopping</Link>
                    </div>
                    <div className="cart-clear">
                      <button onClick={handleClearCart}>
                        Clear Shopping Cart
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-4 col-md-12">
                  <div className="grand-totall">
                    <div className="title-wrap">
                      <h4 className="cart-bottom-title section-bg-gary-cart">
                        Cart Total
                      </h4>
                    </div>
                    <h5>
                      Caution Deposit <span>{cautionDeposit}</span>
                    </h5>
                    <h5>
                      Buying Price <span>{buyingAmount}</span>
                    </h5>
                    <h5>
                      Grand Total <span>{cautionDeposit + buyingAmount}</span>
                    </h5>
                    <button
                      onClick={sendWhatsAppMessage}
                      className="whatsapp-button" // Apply the CSS class
                    >
                      Checkout with Whatsapp
                    </button>
                  </div>
                </div>
              </div>
            </Fragment>
          ) : (
            <div className="row">
              <div className="col-lg-12">
                <div className="item-empty-area text-center">
                  <div className="item-empty-area__icon mb-30">
                    <i className="pe-7s-cart"></i>
                  </div>
                  <div
                    className="item-empty-area__text"
                    style={{ marginTop: "200px", marginBottom: "320px" }}
                  >
                    No items found in cart <br />
                    <Link to="/">Add Items</Link>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};
